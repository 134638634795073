import React, { useState, useEffect } from 'react';
import axios from 'axios';

const API_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

const AppTest = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchTCGPlayerListings = async () => {
    setLoading(true);
    setError(null);
    try {
      // Use the specific product ID for Grim Monolith
      const productId = 6323;
      
      console.log(`Fetching TCGPlayer listings for product ID: ${productId}`);
      
      const response = await axios.post(`${API_URL}/api/tcgplayer/listings`, {
        productIds: [productId]
      });
      
      setData(response.data);
      
      // Display the results in a more readable format
      if (response.data && response.data.results && response.data.results[productId]) {
        const result = response.data.results[productId];
        console.log(`Results for ${result.name} (ID: ${productId}):`);
        
        if (result.cheapestByConditionAndPrinting) {
          console.log('Cheapest listings by condition and printing:');
          Object.entries(result.cheapestByConditionAndPrinting).forEach(([key, data]) => {
            console.log(`${key}: $${data.price} (Seller: ${data.listing.sellerName})`);
          });
        } else {
          console.log('No listings found');
        }
      }
    } catch (err) {
      console.error('Error fetching TCGPlayer listings:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <h1>TCGPlayer Listings Test</h1>
      <p>This page tests the TCGPlayer listings API for Grim Monolith (Product ID: 6323)</p>
      
      <button 
        onClick={fetchTCGPlayerListings}
        style={{ 
          padding: '10px 15px', 
          backgroundColor: '#4CAF50', 
          color: 'white', 
          border: 'none', 
          borderRadius: '4px',
          cursor: 'pointer',
          marginBottom: '20px'
        }}
      >
        Fetch TCGPlayer Listings
      </button>
      
      {loading && <p>Loading...</p>}
      
      {error && (
        <div style={{ color: 'red', marginBottom: '20px' }}>
          <h3>Error:</h3>
          <p>{error}</p>
        </div>
      )}
      
      {data && (
        <div>
          <h2>Results:</h2>
          <pre style={{ 
            backgroundColor: '#f5f5f5', 
            padding: '15px', 
            borderRadius: '4px',
            overflow: 'auto',
            maxHeight: '500px'
          }}>
            {JSON.stringify(data, null, 2)}
          </pre>
          
          {data.results && data.results[6323] && data.results[6323].cheapestByConditionAndPrinting && (
            <div>
              <h3>Cheapest Listings by Condition and Printing:</h3>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr>
                    <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Condition | Printing</th>
                    <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Price</th>
                    <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Seller</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(data.results[6323].cheapestByConditionAndPrinting).map(([key, data]) => (
                    <tr key={key}>
                      <td style={{ border: '1px solid #ddd', padding: '8px' }}>{key}</td>
                      <td style={{ border: '1px solid #ddd', padding: '8px' }}>${data.price.toFixed(2)}</td>
                      <td style={{ border: '1px solid #ddd', padding: '8px' }}>{data.listing.sellerName}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              
              <h3>Testing Display Logic:</h3>
              <div style={{ marginTop: '20px' }}>
                <h4>Near Mint | Normal:</h4>
                <p>
                  {(() => {
                    const condition = "Near Mint";
                    const printing = "Normal";
                    const key = `${condition}|${printing}`;
                    const tcgListing = data.results[6323].cheapestByConditionAndPrinting[key];
                    
                    if (tcgListing && tcgListing.price != null) {
                      return `$${tcgListing.price.toFixed(2)} (${condition})`;
                    } else {
                      return 'N/A';
                    }
                  })()}
                </p>
                
                <h4>Heavily Played | Normal:</h4>
                <p>
                  {(() => {
                    const condition = "Heavily Played";
                    const printing = "Normal";
                    const key = `${condition}|${printing}`;
                    const tcgListing = data.results[6323].cheapestByConditionAndPrinting[key];
                    
                    if (tcgListing && tcgListing.price != null) {
                      return `$${tcgListing.price.toFixed(2)} (${condition})`;
                    } else {
                      return 'N/A';
                    }
                  })()}
                </p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AppTest;

import React, { useState } from 'react';
import './Cart.css';

const Cart = ({ cartItems, removeFromCart, checkout, updateQuantity, togglePaymentType, updateOfferPrice, userSettings, clearCart, shopifyCustomer }) => {
  const [editingPrice, setEditingPrice] = useState({});

  const cashSubtotal = cartItems.reduce((sum, item) => 
    sum + (item.isCash ? parseFloat(item.cashPrice) * item.quantity : 0), 0);
  const creditSubtotal = cartItems.reduce((sum, item) => 
    sum + (!item.isCash ? parseFloat(item.creditPrice) * item.quantity : 0), 0);
  const total = cashSubtotal + creditSubtotal;

  const currencySymbols = {
    USD: '$', EUR: '€', GBP: '£', JPY: '¥', CAD: 'C$', AUD: 'A$', CHF: 'CHF', CNY: '¥', SEK: 'kr', NZD: 'NZ$',
    MXN: 'Mex$', SGD: 'S$', HKD: 'HK$', NOK: 'kr', KRW: '₩', TRY: '₺', RUB: '₽', INR: '₹', BRL: 'R$', ZAR: 'R'
  };

  const currencySymbol = currencySymbols[userSettings?.currency || 'USD'] || '$';

  const handlePriceEdit = (item, newPrice) => {
    setEditingPrice({...editingPrice, [item.cartItemId]: newPrice});
  };

  const handlePriceEditSubmit = (item) => {
    const newPrice = parseFloat(editingPrice[item.cartItemId]);
    if (!isNaN(newPrice) && newPrice >= 0) {
      updateOfferPrice(item, newPrice, item.isCash);
    }
    setEditingPrice({...editingPrice, [item.cartItemId]: undefined});
  };

  return (
    <div className="cart">
      {shopifyCustomer && (
        <div className="customer-info-panel">
          <h3>Customer Info</h3>
          <p><strong>Name:</strong> {`${shopifyCustomer.first_name || ''} ${shopifyCustomer.last_name || ''}`.trim()}</p>
          <p><strong>Email:</strong> {shopifyCustomer.email}</p>
          <p><strong>Shopify ID:</strong> {shopifyCustomer.id?.$numberLong || shopifyCustomer.id || 'N/A'}</p>
        </div>
      )}
      
      {cartItems.length === 0 ? (
        <p className="cart-empty">Your cart is empty</p>
      ) : (
        <>
          {/* Cart summary moved to the top */}
          <div className="cart-summary">
            <div className="subtotal">
              <span>Cash Subtotal:</span>
              <span>{currencySymbol}{cashSubtotal.toFixed(2)}</span>
            </div>
            <div className="subtotal">
              <span>Credit Subtotal:</span>
              <span>{currencySymbol}{creditSubtotal.toFixed(2)}</span>
            </div>
            <div className="total">
              <span>Total:</span>
              <span>{currencySymbol}{total.toFixed(2)}</span>
            </div>
          </div>
          
          <div className="cart-items-container">
            <ul className="cart-items">
              {cartItems.map((item, index) => (
                <li key={`${item._id || item.name}-${item.condition}-${item.printing}-${index}`} className="cart-item">
                  <div className="item-details">
                    <span className="item-name">{item.name}</span>
                    <span className="item-condition">Condition: {item.condition}</span>
                    <span className="item-printing">Print: {item.printing}</span>
                    {editingPrice[item.cartItemId] !== undefined ? (
                      <div className="price-edit">
                        <input
                          type="number"
                          value={editingPrice[item.cartItemId]}
                          onChange={(e) => handlePriceEdit(item, e.target.value)}
                          step="0.01"
                          min="0"
                        />
                        <button onClick={() => handlePriceEditSubmit(item)}>Save</button>
                      </div>
                    ) : (
                      <div className="editable-price">
                        <span className="item-price" onClick={() => setEditingPrice({...editingPrice, [item.cartItemId]: item.isCash ? item.cashPrice : item.creditPrice})}>
                          {currencySymbol}{item.isCash ? item.cashPrice : item.creditPrice} {item.isCash ? 'Cash' : 'Credit'}
                        </span>
                        <span className="edit-price-hint">(Edit)</span>
                      </div>
                    )}
                  </div>
                  <div className="item-actions">
                    <div className="quantity-payment-wrapper">
                      <div className="quantity-control">
                        <button 
                          onClick={() => updateQuantity(item, item.quantity - 1)}
                          disabled={item.quantity <= 1}
                          className="quantity-button"
                        >
                          -
                        </button>
                        <span className="item-quantity">{item.quantity}</span>
                        <button 
                          onClick={() => updateQuantity(item, item.quantity + 1)}
                          disabled={item.quantity >= 4}
                          className="quantity-button"
                        >
                          +
                        </button>
                      </div>
                      <button 
                        className="toggle-payment-button"
                        onClick={() => togglePaymentType(item)}
                      >
                        {item.isCash ? 'Switch to Credit' : 'Switch to Cash'}
                      </button>
                    </div>
                    <button 
                      className="remove-button"
                      onClick={() => removeFromCart(item)}
                      aria-label={`Remove ${item.name} from cart`}
                    >
                      Remove
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          
          <div className="cart-actions">
            <button 
              className="checkout-button"
              onClick={checkout}
            >
              Proceed to Checkout
            </button>
            <button 
              className="clear-cart-button"
              onClick={clearCart}
            >
              Clear All
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Cart;

import React, { useState } from 'react';
import './GridView.css';

const GridView = ({ cards, calculatePrice, calculateSteppedPrice, userSettings, onRowClick, currencySymbol, addToCart, exchangeRates, userCurrency }) => {
  const formatPrice = (price) => {
    if (isNaN(price) || price === null) return 'N/A';
    return `${currencySymbol}${price.toFixed(2)}`;
  };

  const [cardStates, setCardStates] = useState(
    cards.map(card => {
      const condition = 'NM';
      const printing = card.skus && card.skus.length > 0 ? card.skus[0].printingName || 'Regular' : 'Regular';

      return {
        condition,
        printing,
        trendPrice: 0 // Initialize with 0, will be updated after component mounts
      };
    })
  );

  const [hoveredCardId, setHoveredCardId] = useState(null);

  // Update trend prices after component mounts and when cards change
  React.useEffect(() => {
    if (cards.length > 0 && userSettings) {
      setCardStates(cards.map(card => {
        const condition = 'NM';
        const printing = card.skus && card.skus.length > 0 ? card.skus[0].printingName || 'Regular' : 'Regular';
        const trendPrice = getTrendPrice(card, condition, printing);

        return {
          condition,
          printing,
          trendPrice
        };
      }));
    }
  }, [cards, userSettings]);

  // Define getTrendPrice function first so it can be used in the initial state
  const getTrendPrice = (card, condition, printing) => {
    // Check if user prefers lowest listed price
    const preferLowestListed = userSettings?.price_preference_order ?
                              userSettings.price_preference_order.indexOf('lowestListedPrice') === 0 ||
                              (userSettings.price_preference_order.indexOf('lowestListedPrice') > -1 &&
                               userSettings.price_preference_order.indexOf('lowestListedPrice') <
                               userSettings.price_preference_order.indexOf('lowPrice')) :
                              true;

    console.log(`In getTrendPrice - User prefers lowest listed price: ${preferLowestListed}`);

    // Check for TCGPlayer listings data first if user prefers lowest listed price
    let lowestListedPrice = null;
    let listingType = null;
    if (preferLowestListed && card.tcgplayerListings) {
      // Map condition abbreviations to full names
      const conditionMap = {
        'NM': 'Near Mint',
        'LP': 'Lightly Played',
        'MP': 'Moderately Played',
        'HP': 'Heavily Played',
        'DM': 'Damaged'
      };

      const fullCondition = conditionMap[condition] || condition;
      const key = `${fullCondition}|${printing}`;

      const tcgListing = card.tcgplayerListings[key];
      if (tcgListing && tcgListing.price != null) {
        lowestListedPrice = tcgListing.price;
        listingType = tcgListing.listingType;
        console.log(`Found TCGPlayer listing with price: ${lowestListedPrice}, type: ${listingType}`);
      }
    }

    // Get the actual SKU for the selected condition
    const currentSku = card.skus.find(sku =>
      sku.langAbbr === 'EN' &&
      sku.printingName === printing &&
      sku.condAbbr === condition
    );

    // Get actual price for current condition
    const actualPrice = currentSku?.lowPrice || 0;

    // Get NM SKU for stepped calculation
    const nmSku = card.skus.find(sku =>
      sku.langAbbr === 'EN' &&
      sku.printingName === printing &&
      sku.condAbbr === 'NM'
    );

    const nmPrice = nmSku?.lowPrice || 0;
    const steppedPrice = calculateSteppedPrice(condition, nmPrice);

    // Determine the base price to use
    let basePrice;

    if (preferLowestListed && lowestListedPrice !== null) {
      // If user prefers lowest listed price and it's available, use it
      basePrice = lowestListedPrice;
      console.log(`Using TCGPlayer Lowest Listed price: ${basePrice}`);
    } else if (actualPrice > 0 && actualPrice < steppedPrice) {
      // Otherwise use TCGPlayer price if it exists and is lower than stepped price
      basePrice = actualPrice;
      console.log(`Using TCGplayer price: ${basePrice}`);
    } else {
      // Fall back to stepped price
      basePrice = steppedPrice;
      console.log(`Using Stepped price: ${basePrice}`);
    }

    // Get the exchange rate for currency conversion
    const exchangeRate = exchangeRates[userCurrency] || 1;
    return basePrice * exchangeRate;
  };

  const handleConditionChange = (index, newCondition) => {
    setCardStates(prevStates => {
      const newStates = [...prevStates];
      const card = cards[index];
      const currentPrinting = newStates[index].printing;
      const newTrendPrice = getTrendPrice(card, newCondition, currentPrinting);
      newStates[index] = {
        ...newStates[index],
        condition: newCondition,
        trendPrice: newTrendPrice
      };
      return newStates;
    });
  };

  const handlePrintingChange = (index, newPrinting) => {
    setCardStates(prevStates => {
      const newStates = [...prevStates];
      const card = cards[index];
      const currentCondition = newStates[index].condition;
      const newTrendPrice = getTrendPrice(card, currentCondition, newPrinting);
      newStates[index] = {
        ...newStates[index],
        printing: newPrinting,
        trendPrice: newTrendPrice
      };
      return newStates;
    });
  };

  const handleAddToCart = (card, isCash, price) => {
    const cardState = cardStates[cards.indexOf(card)];
    // Find the correct SKU based on condition and printing
    const relevantSku = card.skus.find(sku =>
      sku.condAbbr === cardState.condition &&
      sku.printingName === cardState.printing
    );
    addToCart(
      card,
      cardState.condition,
      cardState.printing,
      1,
      cardState.trendPrice,
      relevantSku?.skuId,
      isCash,
      price
    );
  };

  const getLowestListedPrice = (card, condition, printing) => {
    // Map condition abbreviations to full names
    const conditionMap = {
      'NM': 'Near Mint',
      'LP': 'Lightly Played',
      'MP': 'Moderately Played',
      'HP': 'Heavily Played',
      'DM': 'Damaged'
    };

    // First check if we have TCGPlayer listings data
    if (card.tcgplayerListings) {
      const fullCondition = conditionMap[condition] || condition;
      const key = `${fullCondition}|${printing}`;

      const tcgListing = card.tcgplayerListings[key];
      if (tcgListing && tcgListing.price != null) {
        return formatPrice(tcgListing.price);
      }
    }

    // If no TCGPlayer listing is found, return N/A
    return 'N/A';
  };

  return (
    <div>
      <div className="grid-container">
        {cards.map((card, index) => {
          const { condition, printing, trendPrice } = cardStates[index];
          const { cashPrice, creditPrice } = calculatePrice(card, condition, printing);

          return (
            <div
              key={card._id}
              className="card-grid-item"
              onClick={() => onRowClick(card)}
            >
              <div className="card-image">
                <img
                  src={card.image || 'https://via.placeholder.com/180x250?text=No+Image'}
                  alt={card.name}
                />
              </div>
              <div className="card-details">
                <h3 className="card-name">{card.name}</h3>
                <p className="card-set">{card.expansionName} {card.number ? `- #${card.number}` : ''}</p>
                <p className="card-rarity">{card.rarity || 'Unknown Rarity'}</p>
                {card.id && <p className="card-id">ID: {card.id}</p>}

                <div className="card-selects">
                  <select
                    value={condition}
                    onChange={(e) => handleConditionChange(index, e.target.value)}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {['NM', 'LP', 'MP', 'HP', 'DM'].map(cond => (
                      <option key={cond} value={cond}>{cond}</option>
                    ))}
                  </select>

                  <select
                    value={printing}
                    onChange={(e) => handlePrintingChange(index, e.target.value)}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {card.skus.map(sku => sku.printingName).filter((v, i, a) => a.indexOf(v) === i).map(print => (
                      <option key={print} value={print}>{print}</option>
                    ))}
                  </select>

                  <select
                    defaultValue="EN"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <option value="EN">EN</option>
                    <option value="JP">JP</option>
                    <option value="DE">DE</option>
                    <option value="FR">FR</option>
                    <option value="IT">IT</option>
                    <option value="ES">ES</option>
                  </select>
                </div>

                <div className="card-prices">
                  <div className="price-row">
                    <span>Trend:</span>
                    <span>{formatPrice(trendPrice)}</span>
                  </div>
                  <div className="price-row">
                    <span>Lowest Listed:</span>
                    <span>
                      {(() => {
                        console.log(`Checking lowest listed for ${card.name}, condition: ${condition}, printing: ${printing}`);

                        // First check if we have TCGPlayer listings data
                        if (card.tcgplayerListings) {
                          console.log('TCGPlayer listings available:', card.tcgplayerListings);

                          // Map condition abbreviations to full names
                          const conditionMap = {
                            'NM': 'Near Mint',
                            'LP': 'Lightly Played',
                            'MP': 'Moderately Played',
                            'HP': 'Heavily Played',
                            'DM': 'Damaged'
                          };

                          // The key format in our data is "condition|printing"
                          const fullCondition = conditionMap[condition] || condition;
                          const key = `${fullCondition}|${printing}`;
                          console.log(`Looking for key: ${key}`);

                          const tcgListing = card.tcgplayerListings[key];
                          if (tcgListing && tcgListing.price != null) {
                            console.log(`Found TCGPlayer listing with price: ${tcgListing.price}`);
                            return `${formatPrice(tcgListing.price)} (${tcgListing.listingType || condition})`;
                          } else {
                            console.log('No matching TCGPlayer listing found for this condition/printing');
                            return 'N/A';
                          }
                        } else {
                          console.log('No TCGPlayer listings available for this card');
                          return 'N/A';
                        }
                      })()}
                    </span>
                  </div>
                </div>

                <div className="card-buttons">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddToCart(card, true, cashPrice);
                    }}
                    className="add-to-cart-btn cash"
                  >
                    Cash: {formatPrice(cashPrice)}
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddToCart(card, false, creditPrice);
                    }}
                    className="add-to-cart-btn credit"
                  >
                    Credit: {formatPrice(creditPrice)}
                  </button>
                </div>

                <div className="price-timestamp">
                  Price fetched at: {new Date().toLocaleString('en-GB')}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GridView;

import React, { useState, useEffect, useCallback } from 'react';
import './CardPopup.css';
import { API_URL } from '../App';

const CardPopup = ({ card, onClose, calculatePrice, userSettings, addToCart, exchangeRates, userCurrency }) => {
  const [selectedCondition, setSelectedCondition] = useState('NM');
  const [selectedPrinting, setSelectedPrinting] = useState(card.skus[0]?.printingName || '');
  const [prices, setPrices] = useState({ cashPrice: 0, creditPrice: 0 });
  const [cashPercentage, setCashPercentage] = useState(100);
  const [creditPercentage, setCreditPercentage] = useState(100);
  const [trendPrice, setTrendPrice] = useState(0);
  const [skuPrices, setSkuPrices] = useState({});
  const [isLoadingPrices, setIsLoadingPrices] = useState(true);
  const [activeTab, setActiveTab] = useState('details'); // 'details' or 'listings'
  const [activeSubTab, setActiveSubTab] = useState('skus'); // 'skus' or 'listings' for the TCGPlayer listings tab

  const currencySymbols = {
    USD: '$', EUR: '€', GBP: '£', JPY: '¥', CAD: 'C$', AUD: 'A$', CHF: 'CHF', CNY: '¥', SEK: 'kr', NZD: 'NZ$',
    MXN: 'Mex$', SGD: 'S$', HKD: 'HK$', NOK: 'kr', KRW: '₩', TRY: '₺', RUB: '₽', INR: '₹', BRL: 'R$', ZAR: 'R'
  };

  const currencySymbol = currencySymbols[userSettings?.currency] || '$';

  const ExternalLinkIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
      <polyline points="15 3 21 3 21 9"></polyline>
      <line x1="10" y1="14" x2="21" y2="3"></line>
    </svg>
  );

  useEffect(() => {
    updatePrices();
  }, [selectedCondition, selectedPrinting]);

  useEffect(() => {
    const fetchSkuPrices = async () => {
      setIsLoadingPrices(true);
      try {
        const skuIds = getFilteredSkus().map(sku => sku.skuId);
        const response = await fetch(`${API_URL}/api/tcgplayer/prices`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ skuIds }),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch prices');
        }

        const data = await response.json();
        const pricesMap = {};
        data.forEach(price => {
          pricesMap[price.skuId] = {
            lowPrice: price.lowPrice,
            marketPrice: price.marketPrice,
            midPrice: price.midPrice,
            highPrice: price.highPrice,
            listPrice: price.listPrice,
            directLowPrice: price.directLowPrice,
            subTypeName: price.subTypeName
          };
        });
        setSkuPrices(pricesMap);
      } catch (error) {
        console.error('Error fetching prices:', error);
      } finally {
        setIsLoadingPrices(false);
      }
    };

    fetchSkuPrices();
    const interval = setInterval(fetchSkuPrices, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, [card.skus]);

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    document.addEventListener('keydown', handleEscKey);
    return () => document.removeEventListener('keydown', handleEscKey);
  }, [onClose]);

  const updatePrices = () => {
    const newTrendPrice = getTrendPrice();
    setTrendPrice(newTrendPrice);
    const { cashPrice, creditPrice } = calculatePrice(card, selectedCondition, selectedPrinting);
    setPrices({
      cashPrice: roundToTwoDecimals(cashPrice),
      creditPrice: roundToTwoDecimals(creditPrice)
    });
    setCashPercentage(roundToTwoDecimals((cashPrice / newTrendPrice) * 100));
    setCreditPercentage(roundToTwoDecimals((creditPrice / newTrendPrice) * 100));
  };

  const roundToTwoDecimals = (value) => {
    return Number(value.toFixed(2));
  };

  const formatPrice = (price) => {
    if (price === null || price === undefined) return 'N/A';
    return `${currencySymbol}${price.toFixed(2)}`;
  };

  const formatTcgPrice = (price, className = '') => {
    if (price === null || price === undefined) return 'N/A';
    const exchangeRate = exchangeRates[userCurrency] || 1;
    const convertedPrice = Number(price) * exchangeRate;
    return (
      <span className={`price-cell ${className}`}>
        {currencySymbol}{convertedPrice.toFixed(2)}
      </span>
    );
  };

  const formatListingPrice = (price) => {
    if (price === null || price === undefined) return 'N/A';
    const exchangeRate = exchangeRates[userCurrency] || 1;
    const convertedPrice = Number(price) * exchangeRate;
    return `${currencySymbol}${convertedPrice.toFixed(2)}`;
  };

  const handleConditionChange = (e) => {
    setSelectedCondition(e.target.value);
  };

  const handlePrintingChange = (e) => {
    setSelectedPrinting(e.target.value);
  };

  const handleCashPriceChange = (e) => {
    const newCashPrice = roundToTwoDecimals(parseFloat(e.target.value));
    setPrices(prev => ({ ...prev, cashPrice: newCashPrice }));
    setCashPercentage(roundToTwoDecimals((newCashPrice / trendPrice) * 100));
  };

  const handleCreditPriceChange = (e) => {
    const newCreditPrice = roundToTwoDecimals(parseFloat(e.target.value));
    setPrices(prev => ({ ...prev, creditPrice: newCreditPrice }));
    setCreditPercentage(roundToTwoDecimals((newCreditPrice / trendPrice) * 100));
  };

  const handleCashPercentageChange = (e) => {
    const newPercentage = roundToTwoDecimals(parseFloat(e.target.value));
    if (!isNaN(newPercentage)) {
      setCashPercentage(newPercentage);
      const newCashPrice = roundToTwoDecimals((trendPrice * newPercentage) / 100);
      setPrices(prev => ({ ...prev, cashPrice: newCashPrice }));
    }
  };

  const handleCreditPercentageChange = (e) => {
    const newPercentage = roundToTwoDecimals(parseFloat(e.target.value));
    if (!isNaN(newPercentage)) {
      setCreditPercentage(newPercentage);
      const newCreditPrice = roundToTwoDecimals((trendPrice * newPercentage) / 100);
      setPrices(prev => ({ ...prev, creditPrice: newCreditPrice }));
    }
  };

  const getTrendPrice = () => {
    // Get the current SKU and its price
    const currentSku = card.skus.find(sku =>
      sku.condAbbr === selectedCondition &&
      sku.printingName === selectedPrinting
    );
    const currentSkuPrice = skuPrices[currentSku?.skuId];
    const actualPrice = currentSkuPrice?.lowPrice || currentSku?.lowPrice || 0;

    // Get NM price for stepped calculation
    const nmSku = card.skus.find(sku =>
      sku.condAbbr === 'NM' &&
      sku.printingName === selectedPrinting
    );
    const nmSkuPrice = skuPrices[nmSku?.skuId];
    const nmPrice = nmSkuPrice?.lowPrice || nmSku?.lowPrice || 0;

    // Calculate stepped price based on NM price
    let steppedPrice;
    switch (selectedCondition) {
      case 'NM': steppedPrice = nmPrice; break;
      case 'LP': steppedPrice = nmPrice * 0.8; break;
      case 'MP': steppedPrice = nmPrice * 0.64; break;
      case 'HP': steppedPrice = nmPrice * 0.512; break;
      case 'DM': steppedPrice = nmPrice * 0.4096; break;
      default: steppedPrice = nmPrice;
    }

    // Always use the lower price between actual and stepped
    const basePrice = Math.min(actualPrice || Infinity, steppedPrice || Infinity);
    // Convert to user's currency
    const exchangeRate = exchangeRates[userCurrency] || 1;
    return roundToTwoDecimals(basePrice * exchangeRate);
  };

  const handleAddToCart = (isCash) => {
    const relevantSku = card.skus.find(sku =>
      sku.condAbbr === selectedCondition &&
      sku.printingName === selectedPrinting
    );
    addToCart(
      card,
      selectedCondition,
      selectedPrinting,
      1,
      trendPrice,
      relevantSku?.skuId,
      isCash,
      isCash ? prices.cashPrice : prices.creditPrice
    );
  };

  const handleOverlayClick = useCallback((e) => {
    if (e.target.classList.contains('card-popup-overlay')) {
      onClose();
    }
  }, [onClose]);

  const getCurrentSkuId = () => {
    const relevantSku = card.skus.find(sku =>
      sku.condAbbr === selectedCondition &&
      sku.printingName === selectedPrinting
    );
    return relevantSku?.skuId || 'N/A';
  };

  const getFilteredSkus = () => {
    return card.skus.filter(sku =>
      sku.langAbbr === 'EN'
    ).sort((a, b) => {
      if (a.printingName !== b.printingName) {
        return a.printingName.localeCompare(b.printingName);
      }
      const condOrder = { NM: 1, LP: 2, MP: 3, HP: 4, DM: 5 };
      return condOrder[a.condAbbr] - condOrder[b.condAbbr];
    });
  };

  const getCurrentListings = () => {
    if (!card.tcgplayerListings) {
      console.log('No TCGPlayer listings available for this card');
      return [];
    }

    // Map condition abbreviations to full names
    const conditionMap = {
      'NM': 'Near Mint',
      'LP': 'Lightly Played',
      'MP': 'Moderately Played',
      'HP': 'Heavily Played',
      'DM': 'Damaged'
    };

    const fullCondition = conditionMap[selectedCondition] || selectedCondition;
    const key = `${fullCondition}|${selectedPrinting}`;

    console.log(`Looking for TCGPlayer listings with key: ${key}`);
    console.log('Available TCGPlayer listings:', card.tcgplayerListings);

    // Get the listing data for the current condition and printing
    const listingData = card.tcgplayerListings[key];
    const results = [];

    if (listingData) {
      console.log('Found TCGPlayer listing data:', listingData);

      // Add standard listing if available
      if (listingData.standard) {
        results.push({
          listing: {
            condition: fullCondition,
            printing: selectedPrinting,
            price: listingData.standard.price,
            shipping: listingData.standard.shipping || 0,
            sellerName: listingData.standard.listing?.sellerName || 'TCGPlayer Seller',
            listingType: 'standard',
            hasImage: listingData.standard.hasImage || false
          }
        });
      }

      // Add custom listing if available
      if (listingData.custom) {
        results.push({
          listing: {
            condition: fullCondition,
            printing: selectedPrinting,
            price: listingData.custom.price,
            shipping: listingData.custom.shipping || 0,
            sellerName: listingData.custom.listing?.sellerName || 'TCGPlayer Seller',
            listingType: 'custom',
            hasImage: listingData.custom.hasImage || false
          }
        });
      }

      // If neither standard nor custom exists but there's a price (old format)
      if (!listingData.standard && !listingData.custom && listingData.price) {
        results.push({
          listing: {
            condition: fullCondition,
            printing: selectedPrinting,
            price: listingData.price,
            shipping: listingData.shipping || 0,
            sellerName: listingData.sellerName || (listingData.listing && listingData.listing.sellerName) || 'TCGPlayer Seller',
            listingType: listingData.listingType || 'unknown'
          }
        });
      }

      return results;
    }

    console.log('No matching TCGPlayer listing found for this condition/printing');
    return [];
  };

  // Get all TCGPlayer listings for all conditions and printings
  const getAllListings = () => {
    if (!card.tcgplayerListings) {
      return [];
    }

    const listings = [];
    
    // Process all listings
    Object.entries(card.tcgplayerListings).forEach(([key, data]) => {
      const [condition, printing] = key.split('|');
      
      // Add standard listing if available
      if (data.standard) {
        listings.push({
          condition,
          printing,
          price: data.standard.price,
          shipping: data.standard.shipping || 0,
          sellerName: data.standard.listing?.sellerName || 'TCGPlayer Seller',
          total: data.standard.price + (data.standard.shipping || 0),
          listingType: 'standard',
          hasImage: data.standard.hasImage || false
        });
      }
      
      // Add custom listing if available
      if (data.custom) {
        listings.push({
          condition,
          printing,
          price: data.custom.price,
          shipping: data.custom.shipping || 0,
          sellerName: data.custom.listing?.sellerName || 'TCGPlayer Seller',
          total: data.custom.price + (data.custom.shipping || 0),
          listingType: 'custom',
          hasImage: data.custom.hasImage || false
        });
      }
      
      // If neither standard nor custom exists but there's a price (old format)
      if (!data.standard && !data.custom && data.price) {
        listings.push({
          condition,
          printing,
          price: data.price,
          shipping: data.shipping || 0,
          sellerName: data.sellerName || (data.listing && data.listing.sellerName) || 'TCGPlayer Seller',
          total: data.price + (data.shipping || 0),
          listingType: data.listingType || 'unknown'
        });
      }
    });
    
    // Sort by printing, then condition, then price
    return listings.sort((a, b) => {
      if (a.printing !== b.printing) {
        return a.printing.localeCompare(b.printing);
      }
      
      if (a.condition !== b.condition) {
        const condOrder = {
          'Near Mint': 1,
          'Lightly Played': 2,
          'Moderately Played': 3,
          'Heavily Played': 4,
          'Damaged': 5
        };
        
        return (condOrder[a.condition] || 99) - (condOrder[b.condition] || 99);
      }
      
      // If same condition and printing, sort by price
      return a.price - b.price;
    });
  };

  // Get all trend prices for all conditions and printings
  const getAllTrendPrices = () => {
    const trendPrices = [];
    const printings = [...new Set(card.skus.map(sku => sku.printingName))];
    const conditions = ['NM', 'LP', 'MP', 'HP', 'DM'];
    
    printings.forEach(printing => {
      conditions.forEach(condition => {
        // Get NM price for this printing
        const nmSku = card.skus.find(sku =>
          sku.condAbbr === 'NM' &&
          sku.printingName === printing
        );
        
        if (nmSku) {
          const nmSkuPrice = skuPrices[nmSku.skuId];
          const nmPrice = nmSkuPrice?.lowPrice || nmSku.lowPrice || 0;
          
          // Calculate stepped price based on NM price
          let steppedPrice;
          switch (condition) {
            case 'NM': steppedPrice = nmPrice; break;
            case 'LP': steppedPrice = nmPrice * 0.8; break;
            case 'MP': steppedPrice = nmPrice * 0.64; break;
            case 'HP': steppedPrice = nmPrice * 0.512; break;
            case 'DM': steppedPrice = nmPrice * 0.4096; break;
            default: steppedPrice = nmPrice;
          }
          
          // Get actual price for this condition and printing
          const currentSku = card.skus.find(sku =>
            sku.condAbbr === condition &&
            sku.printingName === printing
          );
          
          const currentSkuPrice = currentSku ? skuPrices[currentSku.skuId] : null;
          const actualPrice = currentSkuPrice?.lowPrice || (currentSku?.lowPrice || 0);
          
          // Use the lower price between actual and stepped
          const basePrice = Math.min(actualPrice || Infinity, steppedPrice || Infinity);
          
          // Convert to user's currency
          const exchangeRate = exchangeRates[userCurrency] || 1;
          const finalPrice = roundToTwoDecimals(basePrice * exchangeRate);
          
          if (finalPrice > 0) {
            trendPrices.push({
              printing,
              condition,
              price: finalPrice,
              source: actualPrice < steppedPrice ? 'TCGplayer' : 'Stepped'
            });
          }
        }
      });
    });
    
    return trendPrices;
  };

  return (
    <div className="card-popup-overlay" onClick={handleOverlayClick}>
      <div className="card-popup">
        <button className="close-button" onClick={onClose}>
          <span className="close-icon">×</span>
        </button>
        
        <div className="card-popup-content">
          <h2 className="card-name">{card.name}</h2>
          
          {/* Main Tabs */}
          <div className="main-tabs">
            <button 
              className={`main-tab-button ${activeTab === 'details' ? 'active' : ''}`}
              onClick={() => setActiveTab('details')}
            >
              Card Details
            </button>
            <button 
              className={`main-tab-button ${activeTab === 'listings' ? 'active' : ''}`}
              onClick={() => setActiveTab('listings')}
            >
              TCGPlayer Listings
            </button>
          </div>
          
          {/* Details Tab Content */}
          {activeTab === 'details' && (
            <div className="card-layout">
              <div className="card-left-column">
                <div className="card-image-container">
                  <img src={card.image} alt={card.name} className="card-image" />
                  <a
                    href={card.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="tcgplayer-link"
                  >
                    View on TCGplayer <ExternalLinkIcon />
                  </a>
                </div>

                <div className="card-details">
                  <p><strong>Expansion:</strong> {card.expansionName}</p>
                  <p><strong>Number:</strong> {card.number || 'N/A'}</p>
                  <p><strong>Selected SKU ID:</strong> {getCurrentSkuId()}</p>
                  <p><strong>Trend Price:</strong> {formatPrice(trendPrice)}</p>
                </div>
              </div>

              <div className="card-right-column">
                <div className="card-options">
                  <div className="options-row">
                    <div className="options-group">
                      <label>Condition:</label>
                      <select value={selectedCondition} onChange={handleConditionChange}>
                        {card.skus
                          .map(sku => sku.condAbbr)
                          .filter((v, i, a) => a.indexOf(v) === i)
                          .map(condition => (
                            <option key={condition} value={condition}>{condition}</option>
                          ))}
                      </select>
                    </div>

                    <div className="options-group">
                      <label>Printing:</label>
                      <select value={selectedPrinting} onChange={handlePrintingChange}>
                        {card.skus
                          .map(sku => sku.printingName)
                          .filter((v, i, a) => a.indexOf(v) === i)
                          .map(printing => (
                            <option key={printing} value={printing}>{printing}</option>
                          ))}
                      </select>
                    </div>
                  </div>

                  <div className="price-inputs">
                    <div className="price-input">
                      <div className="price-input-controls">
                        <label>Cash Offer:</label>
                        <input
                          type="number"
                          value={prices.cashPrice.toFixed(2)}
                          onChange={handleCashPriceChange}
                          step="0.01"
                          min="0"
                        />
                        <div className="slider-container">
                          <input
                            type="range"
                            value={cashPercentage}
                            onChange={handleCashPercentageChange}
                            min="0"
                            max="100"
                            step="0.01"
                            className="slider"
                          />
                          <span className="percentage-badge">{cashPercentage.toFixed(2)}%</span>
                        </div>
                      </div>
                      <button onClick={() => handleAddToCart(true)} className="add-to-cart-btn cash">
                        Add to Cart (Cash)
                      </button>
                    </div>

                    <div className="price-input">
                      <div className="price-input-controls">
                        <label>Credit Offer:</label>
                        <input
                          type="number"
                          value={prices.creditPrice.toFixed(2)}
                          onChange={handleCreditPriceChange}
                          step="0.01"
                          min="0"
                        />
                        <div className="slider-container">
                          <input
                            type="range"
                            value={creditPercentage}
                            onChange={handleCreditPercentageChange}
                            min="0"
                            max="100"
                            step="0.01"
                            className="slider"
                          />
                          <span className="percentage-badge">{creditPercentage.toFixed(2)}%</span>
                        </div>
                      </div>
                      <button onClick={() => handleAddToCart(false)} className="add-to-cart-btn credit">
                        Add to Cart (Credit)
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          
          {/* TCGPlayer Listings Tab Content */}
          {activeTab === 'listings' && (
            <div className="listings-container">
              <div className="listings-header">
                <h3>TCGPlayer Listings for All Conditions and Printings</h3>
              </div>
              
              <div className="table-container">
                {getAllListings().length > 0 ? (
                  <table className="listings-table">
                    <thead>
                      <tr>
                        <th>Printing</th>
                        <th>Condition</th>
                        <th>Type</th>
                        <th>Price</th>
                        <th>Shipping</th>
                        <th>Total</th>
                        <th>Seller</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getAllListings().map((listing, index) => (
                        <tr key={index} className={listing.printing === selectedPrinting && listing.condition === selectedCondition ? 'selected-row' : ''}>
                          <td>{listing.printing}</td>
                          <td>{listing.condition}</td>
                          <td>{listing.listingType || 'N/A'}{listing.hasImage ? ' (with image)' : ''}</td>
                          <td>{formatListingPrice(listing.price)}</td>
                          <td>{formatListingPrice(listing.shipping)}</td>
                          <td>{formatListingPrice(listing.total)}</td>
                          <td>{listing.sellerName}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="no-listings-message">
                    No TCGPlayer listings found for this card.
                  </div>
                )}
              </div>
              
              <div className="sku-list">
                <div className="tab-navigation">
                  <button
                    className={`tab-button ${activeSubTab === 'skus' ? 'active' : ''}`}
                    onClick={() => setActiveSubTab('skus')}
                  >
                    Available SKUs
                  </button>
                  <button
                    className={`tab-button ${activeSubTab === 'listings' ? 'active' : ''}`}
                    onClick={() => setActiveSubTab('listings')}
                  >
                    Live Listings
                  </button>
                  {isLoadingPrices && <span className="loading-indicator">Loading prices...</span>}
                </div>
                <div className="table-container">
                  {activeSubTab === 'skus' && (
                    <table>
                      <thead>
                        <tr>
                          <th>SKU ID</th>
                          <th>Print</th>
                          <th>Cond</th>
                          <th>Market</th>
                          <th>Low</th>
                          <th>Mid</th>
                          <th>High</th>
                          <th>List</th>
                          <th>Direct Low</th>
                        </tr>
                      </thead>
                      <tbody>
                        {getFilteredSkus().map(sku => {
                          const prices = skuPrices[sku.skuId] || {};
                          return (
                            <tr key={sku.skuId}>
                              <td>{sku.skuId}</td>
                              <td>{sku.printingName}</td>
                              <td>{sku.condAbbr}</td>
                              <td>{formatTcgPrice(prices.marketPrice, 'market')}</td>
                              <td>{formatTcgPrice(prices.lowPrice, 'low')}</td>
                              <td>{formatTcgPrice(prices.midPrice, 'mid')}</td>
                              <td>{formatTcgPrice(prices.highPrice, 'high')}</td>
                              <td>{formatTcgPrice(prices.listPrice, 'list')}</td>
                              <td>{formatTcgPrice(prices.directLowPrice, 'direct')}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}

                  {activeSubTab === 'listings' && (
                    <div>
                      {getCurrentListings().length > 0 ? (
                        <table className="listings-table">
                          <thead>
                            <tr>
                              <th>Condition</th>
                              <th>Printing</th>
                              <th>Type</th>
                              <th>Price</th>
                              <th>Seller</th>
                              <th>Shipping</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {getCurrentListings().map((listing, index) => {
                              const listingData = listing.listing;
                              return (
                                <tr key={index}>
                                  <td>{listingData.condition}</td>
                                  <td>{listingData.printing}</td>
                                  <td>{listingData.listingType || 'N/A'}{listingData.hasImage ? ' (with image)' : ''}</td>
                                  <td>{formatListingPrice(listingData.price)}</td>
                                  <td>{listingData.sellerName}</td>
                                  <td>{formatListingPrice(listingData.shipping)}</td>
                                  <td>{formatListingPrice(listingData.price + listingData.shipping)}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      ) : (
                        <div className="no-listings-message">
                          No live listings found for {selectedCondition} {selectedPrinting} condition.
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardPopup;

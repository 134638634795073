import React, { useState } from 'react';
import './TableView.css';

const TableView = ({ cards, calculatePrice, calculateSteppedPrice, userSettings, onRowClick, currencySymbol, addToCart, exchangeRates, userCurrency }) => {
  const formatPrice = (price) => {
    if (isNaN(price) || price === null) return 'N/A';
    return `${currencySymbol}${price.toFixed(2)}`;
  };

  // Define getTrendPrice function first so it can be used in the initial state
  const getTrendPrice = (card, condition, printing) => {
    // Check if user prefers lowest listed price
    const preferLowestListed = userSettings?.price_preference_order ?
                              userSettings.price_preference_order.indexOf('lowestListedPrice') === 0 ||
                              (userSettings.price_preference_order.indexOf('lowestListedPrice') > -1 &&
                               userSettings.price_preference_order.indexOf('lowestListedPrice') <
                               userSettings.price_preference_order.indexOf('lowPrice')) :
                              true;

    console.log(`In getTrendPrice - User prefers lowest listed price: ${preferLowestListed}`);

    // Check for TCGPlayer listings data first if user prefers lowest listed price
    let lowestListedPrice = null;
    let listingType = null;
    if (preferLowestListed && card.tcgplayerListings) {
      // Map condition abbreviations to full names
      const conditionMap = {
        'NM': 'Near Mint',
        'LP': 'Lightly Played',
        'MP': 'Moderately Played',
        'HP': 'Heavily Played',
        'DM': 'Damaged'
      };

      const fullCondition = conditionMap[condition] || condition;
      const key = `${fullCondition}|${printing}`;

      const tcgListing = card.tcgplayerListings[key];
      if (tcgListing && tcgListing.price != null) {
        lowestListedPrice = tcgListing.price;
        listingType = tcgListing.listingType;
        console.log(`Found TCGPlayer listing with price: ${lowestListedPrice}, type: ${listingType}`);
      }
    }

    // Get the actual SKU for the selected condition
    const currentSku = card.skus.find(sku =>
      sku.langAbbr === 'EN' &&
      sku.printingName === printing &&
      sku.condAbbr === condition
    );

    // Get actual price for current condition
    const actualPrice = currentSku?.lowPrice || 0;

    // Get NM SKU for stepped calculation
    const nmSku = card.skus.find(sku =>
      sku.langAbbr === 'EN' &&
      sku.printingName === printing &&
      sku.condAbbr === 'NM'
    );

    const nmPrice = nmSku?.lowPrice || 0;
    const steppedPrice = calculateSteppedPrice(condition, nmPrice);

    // Determine the base price to use
    let basePrice;

    if (preferLowestListed && lowestListedPrice !== null) {
      // If user prefers lowest listed price and it's available, use it
      basePrice = lowestListedPrice;
      console.log(`Using TCGPlayer Lowest Listed price: ${basePrice}`);
    } else if (actualPrice > 0 && actualPrice < steppedPrice) {
      // Otherwise use TCGPlayer price if it exists and is lower than stepped price
      basePrice = actualPrice;
      console.log(`Using TCGplayer price: ${basePrice}`);
    } else {
      // Fall back to stepped price
      basePrice = steppedPrice;
      console.log(`Using Stepped price: ${basePrice}`);
    }

    // Get the exchange rate for currency conversion
    const exchangeRate = exchangeRates[userCurrency] || 1;
    return basePrice * exchangeRate;
  };

  // Initialize cardStates after getTrendPrice is defined
  const [cardStates, setCardStates] = useState(
    cards.map(card => {
      const condition = 'NM';
      const printing = card.skus[0]?.printingName || 'Regular';

      // Use getTrendPrice to calculate the initial trend price
      // This ensures we use the lowest listed price if preferred
      const trendPrice = getTrendPrice(card, condition, printing);

      return {
        condition,
        printing,
        trendPrice
      };
    })
  );

  const handleConditionChange = (index, newCondition) => {
    setCardStates(prevStates => {
      const newStates = [...prevStates];
      const card = cards[index];
      const currentPrinting = newStates[index].printing;
      const newTrendPrice = getTrendPrice(card, newCondition, currentPrinting);
      newStates[index] = {
        ...newStates[index],
        condition: newCondition,
        trendPrice: newTrendPrice
      };
      return newStates;
    });
  };

  const handlePrintingChange = (index, newPrinting) => {
    setCardStates(prevStates => {
      const newStates = [...prevStates];
      const card = cards[index];
      const currentCondition = newStates[index].condition;
      const newTrendPrice = getTrendPrice(card, currentCondition, newPrinting);
      newStates[index] = {
        ...newStates[index],
        printing: newPrinting,
        trendPrice: newTrendPrice
      };
      return newStates;
    });
  };

  const handleAddToCart = (card, isCash, price) => {
    const cardState = cardStates[cards.indexOf(card)];
    // Find the correct SKU based on condition and printing
    const relevantSku = card.skus.find(sku =>
      sku.condAbbr === cardState.condition &&
      sku.printingName === cardState.printing
    );
    addToCart(
      card,
      cardState.condition,
      cardState.printing,
      1,
      cardState.trendPrice,
      relevantSku?.skuId,
      isCash,
      price
    );
  };

  return (
    <div>
      <div className="table-responsive">
        <table className="card-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Expansion</th>
              <th>Number</th>
              <th>Condition</th>
              <th>Printing</th>
              <th>Trend Price</th>
              <th>Lowest Listed</th>
              <th>Cash Offer</th>
              <th>Credit Offer</th>
            </tr>
          </thead>
          <tbody>
            {cards.map((card, index) => {
              const { condition, printing, trendPrice } = cardStates[index];
              const { cashPrice, creditPrice } = calculatePrice(card, condition, printing);

              console.log('Card data:', card);

              return (
                <tr
                  key={card._id}
                  onClick={() => onRowClick(card)}
                >
                  <td>{card.name}</td>
                  <td>{card.expansionName}</td>
                  <td>{card.number}</td>
                  <td>
                    <select
                      value={condition}
                      onChange={(e) => handleConditionChange(index, e.target.value)}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {['NM', 'LP', 'MP', 'HP', 'DM'].map(cond => (
                        <option key={cond} value={cond}>{cond}</option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <select
                      value={printing}
                      onChange={(e) => handlePrintingChange(index, e.target.value)}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {card.skus.map(sku => sku.printingName).filter((v, i, a) => a.indexOf(v) === i).map(print => (
                        <option key={print} value={print}>{print}</option>
                      ))}
                    </select>
                  </td>
                  <td>{formatPrice(trendPrice)}</td>
                  <td>
                    {(() => {
                      console.log(`Checking lowest listed for ${card.name}, condition: ${condition}, printing: ${printing}`);

                      // First check if we have TCGPlayer listings data
                      if (card.tcgplayerListings) {
                        console.log('TCGPlayer listings available:', card.tcgplayerListings);

                        // Map condition abbreviations to full names
                        const conditionMap = {
                          'NM': 'Near Mint',
                          'LP': 'Lightly Played',
                          'MP': 'Moderately Played',
                          'HP': 'Heavily Played',
                          'DM': 'Damaged'
                        };

                        // The key format in our data is "condition|printing"
                        const fullCondition = conditionMap[condition] || condition;
                        const key = `${fullCondition}|${printing}`;
                        console.log(`Looking for key: ${key}`);

                        const tcgListing = card.tcgplayerListings[key];
                        if (tcgListing && tcgListing.price != null) {
                          console.log(`Found TCGPlayer listing with price: ${tcgListing.price}`);
                          return `${formatPrice(tcgListing.price)} (${tcgListing.listingType || condition})`;
                        } else {
                          console.log('No matching TCGPlayer listing found for this condition/printing');
                          return 'N/A';
                        }
                      } else {
                        console.log('No TCGPlayer listings available for this card');
                        return 'N/A';
                      }
                    })()}
                  </td>
                  <td>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleAddToCart(card, true, cashPrice);
                      }}
                      className="add-to-cart-btn cash"
                    >
                      {formatPrice(cashPrice)}
                    </button>
                  </td>
                  <td>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleAddToCart(card, false, creditPrice);
                      }}
                      className="add-to-cart-btn credit"
                    >
                      {formatPrice(creditPrice)}
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableView;
